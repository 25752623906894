<template>
  <div class="landing-container" style="align-items: center; padding: 3em;">
    <v-card class="contact-card">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="auto">
            <span class="text-h3 title-break">
              Contact us.
            </span>
          </v-col>
          <v-col cols="auto">
            <p class="text-h6 text-right azure-text">
              pharmacare@eastman.com
              <br/>
              1-800-327-8626
            </p>
          </v-col>
        </v-row>
        
      </v-card-title>
      <v-card-text class="mb-8 text-body-1">
        Contact us for more information, technical support, or sample requests.
      </v-card-text>
      <v-row>
        <v-col cols="6">
          <div
            class="font-weight-bold mb-4"
          >
            <span>First Name</span>
          </div>
          <v-text-field
            dense
            v-model="currentUserInfo.givenName"
            disabled
            filled
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div
            class="font-weight-bold mb-4"
          >
            <span>Last Name</span>
          </div>
          <v-text-field
            dense
            v-model="currentUserInfo.surname"
            disabled
            filled
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-top: 0px">
        <v-col cols="6">
          <div
            class="font-weight-bold mb-4"
          >
            <span>Email</span>
          </div>
          <v-text-field
            dense
            v-model="currentUserInfo.mail"
            disabled
            filled
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div
            class="font-weight-bold mb-4"
          >
            <span>Phone</span>
          </div>
          <v-text-field
            dense
            v-model="currentUserInfo.mobilePhone"
            disabled
            filled
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-top: 0px">
        <v-col cols="6">
          <div
            class="font-weight-bold mb-4"
          >
            <span>Job Title</span>
          </div>
          <v-text-field
            dense
            v-model="currentUserInfo.jobTitle"
            disabled
            filled
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div
            class="font-weight-bold mb-4"
          >
            <span>Organization</span>
          </div>
          <v-text-field
            dense
            v-model="currentUserInfo.organization"
            disabled
            filled
          >
          </v-text-field>
        </v-col>
      </v-row>
      <div
        class="font-weight-bold mb-4"
      >
        <span>Country</span>
      </div>
      <v-text-field
        dense
        v-model="currentUserInfo.country"
        disabled
        filled
      >
      </v-text-field>

      <div id="contact">
        <!-- <script src="//info.eastman.com/js/forms2/js/forms2.min.js"></script> -->
        <form id="mktoForm_3998"></form>
        <!-- <script>MktoForms2.loadForm("//info.eastman.com", "820-JEE-456", 3998);</script> -->
      </div>
    </v-card>
  </div>
</template>

<script>
// const SCRIPT_SRC = '//info.eastman.com/js/forms2/js/forms2.min.js'
const LAUNCH_FORM = 'MktoForms2.loadForm("//info.eastman.com", "820-JEE-456", 3998);'
import { mapState } from 'vuex' 

export default {
  Name: "ContactUs",
  computed: {
    ...mapState(['currentUserInfo'])
  },
  mounted: function () {
    if (!document.getElementById('formlaunch'))
    {
      var contactDiv = document.getElementById('contact')
      // var scriptElement = document.createElement('script')
      // scriptElement.setAttribute('src', SCRIPT_SRC)
      // document.head.appendChild(scriptElement)

      var launchFormScript = document.createElement('script')
      launchFormScript.id = 'formlaunch'
      launchFormScript.innerHTML = LAUNCH_FORM

      // contactDiv.appendChild(scriptElement)
      contactDiv.appendChild(launchFormScript)
    }   
  },
}
</script>

<style>
#contact {
  min-height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-container {
  padding: 5em 10em 5em 10em;
}

.contact-card { 
  padding: 3em;
}

</style>